import { MAGIC_PUBLIC_KEY } from './constants'
import { AuthServiceConfig } from './types'
export const validateConfig = (config: AuthServiceConfig) => {
  if (!MAGIC_PUBLIC_KEY) {
    console.warn(`No NEXT_PUBLIC_MAGIC_PUBKEY variable found in .env file`)
  }

  if (!config.loginPath) {
    throw new Error('Must provide loginPath parameter in constructor')
  }

  if (!config.redirectPath) {
    throw new Error('Must Provide redirectPath parameter in constructor')
  }

  if (!config.signOutPath) {
    throw new Error('Must Provide signOutPath parameter in constructor')
  }
}
