import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FormikValues, useFormik } from 'formik'
import { useState } from 'react'
import { authClient } from 'service/auth/client'
import * as yup from 'yup'

const initialValues: FormikValues = {
  email: ''
}

const validationSchema = yup.object().shape({
  email: yup.string().trim().email().required('*')
})

export default function EmailLoginForm() {
  // const handleError = (e: any) => console.table({ error: e.message })
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ email }, { setSubmitting }) => {
      try {
        setSubmitting(true)
        setError('')
        const response = await authClient.loginUserWithEmail(email)
        if (!response) return
        if (response.status === 200) {
          window.location.reload()
        } else if (response.status === 400) {
          setError(response.data.reason)
        }
      } catch (e: any) {
        console.error(e)
        setError(e.message)
      } finally {
        setSubmitting(false)
      }
    }
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.5em'
      }}
    >
      <TextField
        label="Email Address"
        disabled={formik.isSubmitting}
        {...formik.getFieldProps('email')}
        type="email"
        size="small"
      />
      <Button disabled={formik.isSubmitting} variant="contained" type="submit">
        Log In
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
    </form>
  )
}
