import { captureException } from '@sentry/nextjs'
import axios, { AxiosError } from 'axios'

const instance = axios.create()

// NOTE: Interceptors added here will apply to both client-side
// and server-side originated requests.
instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const status = error.response?.status || 500
    if (status >= 500) {
      captureException(error)
    }
    return Promise.reject(error)
  }
)

export default instance
