import { isBrowser } from 'utils'
interface ChatlioFunctions {
  identify(user_id: string, properties?: Record<string, string>): void
}

class ChatlioClient implements ChatlioFunctions {
  init = async (): Promise<void> => {
    if (!isBrowser) {
      return
    }
    const widget_exists = document.getElementById('chatlio-widget-embed')
    if (widget_exists && window.ChatlioReact && window._chatlio) {
      return void (window._chatlio as any).init(
        widget_exists,
        window.ChatlioReact
      )
    }
    var chatlioScript = document.createElement('script')
    var chatlioTag = document.getElementsByTagName('script')[0]
    chatlioScript.id = 'chatlio-widget-embed'
    chatlioScript.src = 'https://w.chatlio.com/w.chatlio-widget.js'
    chatlioScript.async = !0
    chatlioScript.setAttribute('data-embed-version', '2.3')
    chatlioScript.setAttribute(
      'data-widget-id',
      '0ff1a1e4-e16d-49c7-4901-0399a66e0802'
    )
    chatlioScript.setAttribute('data-disable-cookies', 'true')
    chatlioScript.setAttribute('data-chatlio-debug', 'true')
    chatlioTag.parentNode!.insertBefore(chatlioScript, chatlioTag)
  }

  identify(user_id: string, properties?: Record<string, string>): void {
    if (!isBrowser) {
      const error = new Error(
        'Attempted to call browser only chatlio API in a non-browser environment'
      )
      throw error
    } else {
      const _chatlio = window._chatlio || []
      const chatArgs = (func: any) => {
        return function () {
          try {
            _chatlio.push([func].concat(arguments))
          } catch (err) {}
        }
      }
      ;(_chatlio as any)['identify'] = chatArgs('identify')
      if (!window._chatlio) {
        window._chatlio = _chatlio
      }
      ;(window._chatlio as any).identify(user_id, {
        ...properties
      })
    }
  }
}

export default ChatlioClient
