import { User } from '.prisma/client'
import { getIronSession } from 'iron-session'
import { withIronSessionApiRoute, withIronSessionSsr } from 'iron-session/next'
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  NextApiHandler
} from 'next'
import { UserMetadata } from './client'
import { withSentry, captureException } from '@sentry/nextjs'
import { Redirect } from 'next'

const sessionOptions = {
  password: process.env.IRON_SESSION_SECRET!,
  cookieName: 'oftk'
}

export function withSessionRoute(handler: NextApiHandler) {
  return withSentry(withIronSessionApiRoute(handler, sessionOptions))
}

export type NextApiHandlerWithUser = NextApiHandler extends (
  ...a: infer U
) => infer R
  ? (user: User, ...a: U) => R
  : never

export function authenticatedRoute(handler: NextApiHandlerWithUser) {
  return withSessionRoute(async (req, res, ...others) => {
    if (!req.session.user) {
      return res.status(401).json({ error: 'No user session' })
    } else {
      return handler(req.session.user, req, res, ...others)
    }
  })
}

export const temporaryRedirect = (
  destination: string
): { redirect: Redirect } => ({
  redirect: {
    destination,
    permanent: false
  }
})

// Theses types are compatible with InferGetStaticPropsType https://nextjs.org/docs/basic-features/data-fetching#typescript-use-getstaticprops
export function withSessionSsr<
  P extends { [key: string]: unknown } = { [key: string]: unknown }
>(
  handler: (
    context: GetServerSidePropsContext
  ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>
) {
  try {
    return withIronSessionSsr(handler, sessionOptions)
  } catch (e: unknown) {
    captureException(e)
    return temporaryRedirect('/')
  }
}

export type GetServerSidePropsContextWithUser =
  GetServerSidePropsContext extends (...a: infer U) => infer R
    ? (user: User, ...a: U) => R
    : never

export function authenticatedSsr<
  P extends { [key: string]: unknown } = { [key: string]: unknown }
>(
  handler: (
    user: User,
    context: GetServerSidePropsContext
  ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>
) {
  return withSessionSsr(async (context: GetServerSidePropsContext) => {
    if (!context.req.session.user) {
      return { props: {} } as any
    } else {
      return handler(context.req.session.user!, context)
    }
  })
}

export function getSession(req: any, res: any) {
  return getIronSession(req, res, sessionOptions)
}

declare module 'iron-session' {
  interface IronSessionData {
    userMetadata?: UserMetadata
    user?: User
  }
}
