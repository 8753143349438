import Toolbar from '@mui/material/Toolbar'
import Appbar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import Link from 'next/link'
import { authClient } from 'service/auth/client'
import Button from '@mui/material/Button'
import { useAuthUser } from 'auth'
import OpenStoreIcon from 'components/icons/OpenStore'
import React from 'react'
import { Logout } from '@mui/icons-material'
import { UserRole } from '@prisma/client'
import { Tooltip } from '@mui/material'

const TopNav = () => {
  const user = useAuthUser()
  const handleSignOut = async () => {
    await authClient.signOut()
    window.location.reload()
  }

  if (user.role !== UserRole.INTERNAL) return <></>
  return (
    <Appbar
      elevation={1}
      sx={{
        backgroundColor: 'white',
        borderBottom: '1px #d3d3d3 solid',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      position="fixed"
    >
      <Toolbar>
        <Grid
          container
          alignItems="center"
          alignContent="flex-start"
          justifyContent="space-between"
        >
          <Grid item width={120}>
            <Link passHref href="/">
              <Button>
                <OpenStoreIcon height={37} />
              </Button>
            </Link>
          </Grid>
          {/* <Grid item>
            Phase links can go here
          </Grid> */}
          <Grid item xs container justifyContent="flex-end">
            {user.role == UserRole.INTERNAL && (
              <>
                <Link passHref href="/">
                  <Button type="button" variant="text">
                    Workspace
                  </Button>
                </Link>
                <Link passHref href="/form_template">
                  <Button type="button" variant="text">
                    Form Templates
                  </Button>
                </Link>
                <Link passHref href="/activity_log">
                  <Button type="button" variant="text">
                    Activity Log
                  </Button>
                </Link>
                <Link passHref href="/metrics">
                  <Button type="button" variant="text">
                    Metrics
                  </Button>
                </Link>
              </>
            )}
            <Tooltip arrow title={user.primary_email}>
              <Button
                color="primary"
                onClick={handleSignOut}
                endIcon={<Logout />}
              >
                Signout
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
    </Appbar>
  )
}

export default TopNav
