import { useEffect, useState } from 'react'
import ChatlioChat from 'service/chatlio/chat'
import { UserRole } from '.prisma/client'

const chatlioChat = new ChatlioChat()

const ChatlioInit = ({ user, pageProps }) => {
  const [chatLoaded, setChatLoaded] = useState(false)
  useEffect(() => {
    ;(async () => {
      try {
        if (user && user.role === UserRole.MERCHANT) {
          await chatlioChat.init()
          setChatLoaded(true)
        }
      } catch (e: unknown) {
        console.error(e)
      }
    })()
  }, [user])
  useEffect(() => {
    if (chatLoaded && user && pageProps) {
      ;(() => {
        try {
          chatlioChat.identify(user.id, {
            email: user.primary_email,
            name: !pageProps.workspaces
              ? pageProps.workspace?.group?.name + ' : ' + user.name
              : pageProps.workspaces[0].group.name + ' : ' + user.name
          })
        } catch (e) {
          console.error('Chatlio app had an error', e)
        }
      })()
    }
  }, [user, chatLoaded, pageProps])

  return <></>
}

export default ChatlioInit
