import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// Create a theme instance.
const theme = createTheme({
  palette: {
    background: {
      default: '#FAFAFC'
    },
    primary: {
      main: '#4b56bb'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    }
  },
  typography: {
    allVariants: {
      lineHeight: '120%'
    },
    h1: {
      fontSize: '28px',
      fontWeight: '400'
    },
    h2: {
      fontSize: '24px',
      fontWeight: '450'
    },
    h3: {
      fontSize: '18px'
    },
    h5: {
      fontSize: '16px'
    },
    caption: {
      fontSize: '10px'
    },
    body2: {
      fontSize: '12px'
    },
    body1: {
      fontSize: '14px'
    }
  }
})

export default theme
