import { User } from '.prisma/client'
import React, { useContext } from 'react'

// We only ever use this context inside the flow app
// _app injects user into the context
// If we're inside the app (not on /auth/ path) there's always a User object in the provider
// otherwise we don't render components using this Context (we show LoginContainer)
export const AuthContext = React.createContext(undefined as any as User)

export function useAuthUser() {
  return useContext(AuthContext)
}
