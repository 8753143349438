import { SVGProps } from 'react'

export default function OpenStoreIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 104 37" {...props}>
      <g clipPath="url(#clip0_1:814)">
        <path
          d="M44.3935 26.8528H40.9432C34.9425 31.563 25.9802 34.6806 18.3684 34.6271C9.91212 34.5095 3.79455 31.5138 2.51189 25.5844C1.56909 24.9974 0.722339 24.2687 0.00144771 23.4238C-0.125393 32.4095 8.09858 36.9124 18.3684 36.9943C27.1111 37.1668 38.3921 32.8293 44.5987 26.8528H44.3935Z"
          fill="#4B56BB"
        />
        <path
          d="M27.0854 14.4973C29.1213 16.5766 29.1284 20.8108 27.0854 22.8837C25.4344 24.8077 21.9826 25.1319 20.0344 23.4659V28.7127H17.4327V13.107H19.9888V14.1111C21.7596 12.1572 25.4401 12.5512 27.0854 14.4973ZM20.7641 16.1975C19.2349 18.15 19.9475 22.3707 22.9781 22.2559C24.9434 22.2909 25.9788 20.5536 25.971 18.7194C25.9831 16.8852 24.947 15.1472 22.9781 15.1821C22.0189 15.1821 21.2809 15.5206 20.7641 16.1975Z"
          fill="#4B56BB"
        />
        <path
          d="M71.4561 24.3104C68.5125 24.2819 67.6403 23.2415 67.5996 20.3392V15.4337H65.9564V13.1071H67.5996V10.1926H70.1785V13.1071H72.8478V15.4351H70.1785V19.9529C70.1785 20.7605 70.3001 21.3007 70.5433 21.5734C70.9388 22.0558 72.0911 22.0009 72.8478 21.9382V24.2662C72.5139 24.2952 72.05 24.3099 71.4561 24.3104Z"
          fill="#4B56BB"
        />
        <path
          d="M75.2044 22.8696C71.6414 19.2888 74.0706 12.7288 79.3117 12.8285C82.5183 12.7672 85.1641 15.4651 85.0836 18.6938C85.1891 23.9078 78.8115 26.4781 75.2044 22.8696ZM77.0642 16.1834C75.2713 18.1786 76.2804 22.2489 79.3117 22.1848C81.2007 22.204 82.4528 20.5693 82.4157 18.6931C82.4535 16.8183 81.2 15.1822 79.3117 15.2014C78.3991 15.2038 77.6499 15.5321 77.0642 16.1862V16.1834Z"
          fill="#4B56BB"
        />
        <path
          d="M92.7561 12.9688V15.4336C89.7247 15.1927 88.8953 16.9457 88.8996 19.9058V24.2875H86.2979V13.107H88.8547V14.3184C89.6592 13.2901 91.1962 12.822 92.7561 12.9688Z"
          fill="#4B56BB"
        />
        <path
          d="M102.49 14.5194C101.479 13.4234 100.094 12.8441 98.4832 12.8441C93.3604 12.7016 91.2226 19.2966 94.4628 22.8509C97.1827 25.7726 102.543 24.8113 103.741 20.8215H101.021C100.786 21.2632 100.43 21.6286 99.9945 21.8746C99.5591 22.1207 99.0624 22.2372 98.563 22.2103C96.8934 22.2103 95.7718 21.1977 95.548 19.4954H103.945C104.183 17.8293 103.632 15.6616 102.49 14.5194ZM95.5936 17.488C95.9043 15.9816 97.0074 15.0502 98.4917 15.0502C99.8884 14.9619 101.193 16.0942 101.303 17.488H95.5936Z"
          fill="#4B56BB"
        />
        <path
          d="M39.0989 14.5194C38.0885 13.4234 36.7032 12.8441 35.0928 12.8441C29.97 12.7016 27.8322 19.2966 31.0724 22.8509C33.7923 25.7726 39.1524 24.8113 40.3509 20.8215H37.6303C37.3956 21.2632 37.0396 21.6286 36.6041 21.8746C36.1687 22.1207 35.672 22.2372 35.1726 22.2103C33.503 22.2103 32.3814 21.1977 32.1576 19.4954H40.5547C40.7927 17.8293 40.2419 15.6616 39.0989 14.5194ZM32.204 17.488C32.5146 15.9816 33.6177 15.0502 35.102 15.0502C36.4987 14.9619 37.8034 16.0942 37.9132 17.488H32.204Z"
          fill="#4B56BB"
        />
        <path
          d="M56.2495 12.6795C56.2495 11.2708 57.3896 10.4891 59.2381 10.4891C61.1877 10.4891 62.231 11.6356 62.3386 13.1057H64.9944C64.8462 10.2304 62.8801 8.052 59.2381 8.052C55.71 8.052 53.5659 10.2339 53.5659 12.7423C53.5659 19.0843 62.4319 16.0487 62.4319 19.9194C62.4319 21.3325 61.1778 22.1057 59.0336 22.1057C56.9856 22.1057 55.8533 21.0688 55.6573 19.494H52.9495C53.1262 22.0166 54.7822 24.5427 58.8811 24.5427C62.677 24.5427 65.1854 22.6828 65.1854 19.7855C65.1868 13.4634 56.2495 16.1612 56.2495 12.6795Z"
          fill="#4B56BB"
        />
        <path
          d="M0.00923994 16.2772C-0.147529 8.90839 8.77834 5.28702 13.8833 10.4127C17.0251 13.4055 17.013 19.1775 13.8833 22.1653C8.74343 27.2917 -0.136841 23.681 0.00923994 16.2772ZM2.79332 16.2772C2.73275 19.4233 4.99237 22.1034 8.17834 22.0492C15.2429 21.9858 15.2665 10.6036 8.17834 10.548C5.01731 10.4903 2.73489 13.1697 2.79332 16.2772Z"
          fill="#4B56BB"
        />
        <path
          d="M44.3935 18.1031V24.2877H41.7925V13.1072H44.3486V14.02C45.726 12.3241 49.1721 12.5157 50.4982 14.134C51.2816 15.001 51.6733 16.2181 51.6733 17.7853V24.2877H49.0723V17.7839C49.0723 16.9929 48.8783 16.3616 48.4901 15.8898C47.7312 14.8794 45.7474 14.9884 45.0327 15.9697C44.6075 16.4941 44.3944 17.2053 44.3935 18.1031Z"
          fill="#4B56BB"
        />
        <path
          d="M51.0719 9.40158C45.6976 -3.8447 23.0494 -0.859676 12.3784 6.32321C13.2034 6.67778 13.9782 7.13921 14.6829 7.69565C20.1592 4.50967 27.2245 2.4902 33.3591 2.52369C41.2988 2.54792 47.2482 5.48663 48.9227 10.4377C49.9121 10.6407 50.8404 11.0716 51.6341 11.6961C51.5293 10.9134 51.3409 10.1441 51.0719 9.40158Z"
          fill="#4B56BB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1:814">
          <rect
            width="104"
            height="36.7994"
            fill="white"
            transform="translate(0 0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
