import { AuthServiceConfig, MagicLoginParameters } from './types'

export * from './withSession'
export * from './types'

import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'
import { AxiosInstance } from 'axios'
import client from 'service/axios'
import { validateConfig } from './utils'
import { MAGIC_PUBLIC_KEY } from './constants'
class AuthClient {
  client: AxiosInstance
  loginPath: string
  redirectPath: string
  signOutPath: string

  constructor(config: AuthServiceConfig) {
    validateConfig(config)
    this.client = client
    this.loginPath = config.loginPath
    this.redirectPath = config.redirectPath
    this.signOutPath = config.signOutPath
  }

  private get magic() {
    return new Magic(MAGIC_PUBLIC_KEY, {
      extensions: [new OAuthExtension()]
    })
  }

  private get signOutURI() {
    const url = this.getBaseUrlObject()
    url.pathname = this.signOutPath
    return url.toString()
  }

  public get loginURI() {
    const url = this.getBaseUrlObject()
    url.pathname = this.loginPath
    return url.toString()
  }

  public signOut = async () => {
    await Promise.all([
      this.magic.user.logout(),
      this.client.get(this.signOutURI)
    ])
    console.log('logged in?', await this.magic.user.isLoggedIn())
  }

  private getBaseUrlObject = (): URL => {
    return new URL(`${window.location.protocol}${window.location.host}`)
  }

  getRedirectURI = (): string => {
    const url = this.getBaseUrlObject()
    url.pathname = window.location.pathname.includes('login_redirect')
      ? this.redirectPath
      : this.redirectPath + window.location.pathname
    return url.toString()
  }

  createHeaders = (didToken: string): { Authorization: string } => ({
    Authorization: `Bearer ${didToken}`
  })

  public async loginWithGoogle() {
    return await this.magic.oauth.loginWithRedirect({
      redirectURI: this.getRedirectURI(),
      provider: 'google'
    })
  }

  public async loginWithMagicLink({ email }: MagicLoginParameters) {
    const didToken = await this.magic.auth.loginWithMagicLink({
      email,
      showUI: true
    })
    if (!didToken) {
      throw new Error('Invalid Token')
    }

    return this.client
      .get(this.loginURI, {
        headers: this.createHeaders(didToken)
      })
      .then((response) => {
        return response
      })
      .catch((err) => {
        if (err.response) {
          return err.response
        } else {
          return err
        }
      })
  }

  public async completeOAuthFlow(destination: string) {
    const oauthRedirectResult = await new Magic(MAGIC_PUBLIC_KEY, {
      extensions: [new OAuthExtension()]
    }).oauth.getRedirectResult()

    const { idToken } = oauthRedirectResult.magic
    await this.client.get(this.loginURI, {
      headers: this.createHeaders(idToken)
    })

    const finalDestinationURL = this.getBaseUrlObject()
    finalDestinationURL.pathname = destination
    window.location.href = finalDestinationURL.toString()
  }

  public async loginUserWithEmail(email: string) {
    const updatedEmail = email.replace(' ', '+')
    const email_split = updatedEmail.split('@')
    if (String(email_split[1]).toLowerCase() === 'open.store') {
      if (!email_split[0].includes('+')) {
        await this.loginWithGoogle()
        return
      }
    }
    const response = await this.loginWithMagicLink({
      email: updatedEmail
    })
    return response
  }
}

export const authClient = new AuthClient({
  loginPath: process.env.NEXT_PUBLIC_MAGIC_AUTH_ENDPOINT || '/api/auth/login',
  redirectPath: process.env.NEXT_PUBLIC_GOOGLE_REDIRECT_ENDPOINT || '/auth/cb',
  signOutPath: process.env.NEXT_PUBLIC_SIGN_OUT_ENDPOINT || '/api/auth/signout'
})
