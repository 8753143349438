import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import OpenStoreIcon from 'components/icons/OpenStore'
import EmailLoginForm from './EmailLoginForm'

const LoginContainer = () => (
  <Grid
    style={{ height: '100vh' }}
    container
    justifyContent="center"
    alignItems="center"
  >
    <Grid
      style={{ height: '400px' }}
      direction="column"
      alignItems="center"
      item
      container
      xs={11}
      md={5}
    >
      <Grid item xs={10} md={4}>
        <Card
          elevation={0}
          sx={{
            padding: '32px',
            backgroundColor: 'background.default'
          }}
        >
          <Grid container alignItems="center">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                textAlign: 'center'
              }}
            >
              <Box>
                <OpenStoreIcon height={50} />
              </Box>
              <EmailLoginForm />
            </div>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </Grid>
)

export default LoginContainer
