import Segment from 'analytics-node'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { SerializableObject } from '../utils'
import { AnalyticsEvent } from './analytics-constants'

const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
const segment = SEGMENT_WRITE_KEY && new Segment(SEGMENT_WRITE_KEY)

export const logEvent = (
  id: string,
  event: AnalyticsEvent,
  eventProps?: SerializableObject
): void => {
  try {
    if (!SEGMENT_WRITE_KEY) return // If we didn't have the write key, just return and don't try logging
    // We a warning for missing NEXT_PUBLIC_SEGMENT_WRITE_KEY
    if (!segment) throw new Error('Cannot load analytics client')
    segment.track(
      {
        userId: id,
        event: event,
        properties: eventProps
      },
      (err: any) => err && console.error(err)
    )
  } catch (e) {
    console.error(e)
    console.error('Error sending event to segment: ', id, event, eventProps)
  }
}

export const pageView = (
  userId?: string,
  name?: string,
  category?: string
): void => {
  if (segment) {
    segment.page({ userId: userId || 'none', category, name, properties: {} })
  }
}

export function LogUserPageView(userId?: string, category?: string): void {
  const { isReady, asPath } = useRouter()

  useEffect(() => {
    if (isReady) {
      pageView(userId, asPath, category || '')
    }
  }, [userId, isReady, asPath, category])
}
