import { Task } from '@prisma/client'
import axios, { AxiosError } from 'axios'
import normalizeUrl from 'normalize-url'

type ObjectKey = string | number | symbol
export type AnyObject = Record<ObjectKey, unknown>
export type SerializableObject = Record<ObjectKey, string | number | boolean>

export const isBrowser = typeof window !== 'undefined'
export const prettyJson = (input: any) => JSON.stringify(input, null, 2)

export const taskURL = (task: Task, phaseSlug: string) =>
  `/workspace/${task.workspace_id}/${phaseSlug}/${task.id}`

export const partnerTaskURL = (task: Task, parent: string) =>
  `/workspace/${parent}/partner/${task.workspace_id}/${task.id}`
export const isAxiosError = (e: unknown): e is AxiosError =>
  axios.isAxiosError(e)

export const normalizeClientUrl = (inputUrl: string) =>
  normalizeUrl(inputUrl, { forceHttp: true, stripWWW: true })

export const validateClientUrl = (inputUrl?: string) => {
  if (!inputUrl) return false
  try {
    const url = new URL(normalizeClientUrl(inputUrl!))
    return !!url
  } catch (e: unknown) {
    console.error(e)
    return false
  }
}
export const normalizeServerUrl = (inputUrl: string) => {
  const normalizedUrl = normalizeUrl(inputUrl.toLowerCase(), {
    forceHttps: true
  })
  const url = new URL(normalizedUrl)
  return url.hostname
}

export function isEmptyObject(obj: any): boolean {
  for (var _i in obj) return false
  return true
}

export const baseRedirect = {
  redirect: {
    destination: '/',
    permanent: false
  }
}

export const titleCase = (value: string): string =>
  /\s/.test(value)
    ? value
        .split(' ')
        .map((segment) => titleCase(segment))
        .join(' ')
    : value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase()

export const isUUID = (uuid: string): boolean => {
  const s = '' + uuid
  const match = s.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  )
  // if not a match then it's not a UUID
  return match !== null
}
