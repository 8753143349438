import Head from 'next/head'
import App, { AppContext, AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import theme from 'styles/theme'
import createEmotionCache from 'createEmotionCache'
import TopNav from 'TopNav'
import { getSession } from 'service/auth/client'
import { User, UserRole } from '.prisma/client'
import { AuthContext } from 'auth'
import { LogUserPageView } from 'analytics/segment-client'
import LoginContainer from 'components/containers/LoginContainer'
import { Box } from '@mui/system'
import './styles.scss'
import ChatlioInit from 'components/chatlio/ChatlioInit'
import { NextPage } from 'next'
import { ReactElement, ReactNode } from 'react'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY!)
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  requireLogin: boolean
  user?: User
  Component: NextPageWithLayout
}

export default function MyApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    requireLogin,
    user
  } = props
  LogUserPageView(user?.id)
  const getPageLayout = Component.getLayout ?? ((page) => page)
  const isInternal = user?.role === UserRole.INTERNAL

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ChatlioInit user={user} pageProps={pageProps} />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <CssBaseline />
          {requireLogin ? (
            user ? (
              <AuthContext.Provider value={user}>
                {isInternal && <TopNav />}
                <Box
                  component="main"
                  sx={{ marginTop: isInternal ? 8 : 0, flexGrow: 1, p: 3 }}
                >
                  {
                    /* @ts-ignore */
                    getPageLayout(<Component {...pageProps} />)
                  }
                </Box>
              </AuthContext.Provider>
            ) : (
              <LoginContainer />
            )
          ) : (
            /* @ts-ignore */
            getPageLayout(<Component {...pageProps} />)
          )}
        </Box>
      </ThemeProvider>
    </CacheProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext
  // All URLs that are not /auth/ require login
  const requireLogin = !!!appContext.router.route.startsWith('/auth/')
  if (ctx.req && ctx.res) {
    const session = await getSession(ctx.req, ctx.res)
    return {
      user: session.user,
      requireLogin
    }
  } else {
    // We'll hit this when we navigate to '/' on the client side
    // we can hit /api/auth/user to fetch current user
    const { user } = await fetch('/api/auth/user').then((r) => r.json())
    const appProps = await App.getInitialProps(appContext)
    return { ...appProps, user, requireLogin }
  }
}
