import { OAuthExtension } from '@magic-ext/oauth'
import { Magic } from 'magic-sdk'
import { NextApiRequest, NextApiResponse } from 'next'

export type MagicWithOAuth = Magic & { oauth: OAuthExtension }

export type MagicApiKey = string

export type AuthErrorHandler = (e: any) => void

export type User = {
  id: string
  email: string
}

export type UserMetadata = {
  issuer: string
  publicAddress: string
  email: string
}

export type CreateOrRetrieveUserResult = {
  user: User | null
  error: Error | null
  success: boolean
}

export type LoginResult = {
  userMetadata: UserMetadata | null
  error: Error | null
}

export type AuthServiceConfig = {
  redirectPath: string
  loginPath: string
  signOutPath: string
}

export type AuthConfig = {
  google: IGoogleAuthService
  magic: IMagicAuthService
}

export type AuthServerConfig = {
  successRedirectPath?: string
  failureRedirectPath?: string
  afterSignOutPath?: string
}

export interface AuthServerRedirects {
  onSuccess: string
  onFailure: string
  onSignOut: string
}

export type MagicLoginParameters = {
  email: string
}

export type CompleteMagicLoginParameters = Omit<
  MagicLoginParameters,
  'email' | 'redirectPath'
>

export type SignOutResult = {
  isSignedOut: boolean
  error?: Error | null
}

export enum AuthServiceType {
  Google = 'google',
  Magic = 'magic'
}

export interface IMagicAuthService {
  loginWithRedirect: (params: MagicLoginParameters) => Promise<void>
  loginWithMagicLink: (params: MagicLoginParameters) => Promise<void>
  completeLoginFlow: (params: CompleteMagicLoginParameters) => Promise<void>
  isAuthedUser: () => Promise<boolean>
  signOut: () => Promise<void>
}

export interface IGoogleAuthService {
  login: (onSdkError?: AuthErrorHandler) => Promise<void>
  completeLoginFlow: (params: CompleteMagicLoginParameters) => Promise<void>
  signOut: () => Promise<void>
}

export interface IAuthServer {
  login: (req: NextApiRequest, res: NextApiResponse) => Promise<LoginResult>
  signOut: (req: NextApiRequest, res: NextApiResponse) => Promise<void>
}

export type CompleteOAuthFlowParams = {
  provider: 'google' | 'magic'
  destination: string
}
